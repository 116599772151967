import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentTravelogObject: {},
    povDataArray: [],
    currentPovObject: {},
    postDataArray: [],
  },
  getters: {
    getCurrentTravelogObject: (state) => {
      return state.currentTravelogObject;
    },
    getPovDataArray: (state) => {
      return state.povDataArray;
    },
    getCurrentPovObject: (state) => {
      return state.currentPovObject;
    },
    getPostDataArray: (state) => {
      return state.postDataArray;
    },
  },
  mutations: {
    setCurrentTravelogObject(state, currentTravelogObject) {
      state.currentTravelogObject = currentTravelogObject;
    },
    setPovDataArray(state, povDataArray) {
      state.povDataArray = povDataArray;
    },
    setCurrentPovObject(state, currentPovObject) {
      state.currentPovObject = currentPovObject;
    },
    setPostDataArray(state, postDataArray) {
      state.postDataArray = postDataArray;
    },
  },
  plugins: [createPersistedState()],
  actions: {},
  modules: {},
});
