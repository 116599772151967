import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome page",
    component: () => import("@/views/Main Pages/WelcomePage.vue"),
  },

  {
    path: "/all_travelogs",
    name: "All Travelogs",
    component: () => import("@/views/Child Pages/ViewAllTravelogs.vue"),
  },

  {
    path: "/travelog_details",
    name: "Travelog Details",
    component: () => import("@/views/Child Pages/TravelogDetails.vue"),
  },

  {
    path: "/pov_post_details",
    name: "Pov Details",
    component: () => import("@/views/Child Pages/PovPostDetails.vue"),
  },

  //   {
  //   path: "/",
  //   name: "Pov Details",
  //   component: () => import("@/views/Child Pages/DummyCard1.vue"),
  // },
  // {
  //   path: "/a",
  //   name: "Pov Details2",
  //   component: () => import("@/views/Child Pages/DummyCard2.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
